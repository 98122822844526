@import "partials/sanitize.scss";
@import "partials/variables.scss";
@import "partials/slick.scss";
@import "partials/slick-theme.scss";

* {
  box-sizing: border-box;
}

a {
  &:focus,
  &:active {
    outline: none
  }
  color: #4a4a4a;
  text-decoration: none;
}

body {
  color: #4A4A4A;
  font-family: 'Open Sans', sans-serif;
  background: #f7f7f7;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

button {
  font: inherit;
  &:focus {
    outline: none
  }
}

section {
  margin-bottom: 25px;
  h3 {
    font-size: 16px;
    line-height: 21px;
    color: #4A4A4A;
    margin: 4px 0;
  }
  .subtitle {
    font-size: 12px;
    line-height: 16px;
    display: block;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
}
section.fullwidth {
  margin: 0 -16px;
}

.slick-slide {
  padding-right: 13px;
}
.slick-initialized .slick-slide {
  display: block;
}

.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
}
