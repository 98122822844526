.siteHeader {
  max-width: 480px;
  margin: 0 auto 5px;
  width: 100%;
  &__logo {
    display: block;
    margin: 0 auto;
    padding: 16px;
  }
  &__filter {
    margin: 15px 0;
  }
  &__filter-btn {
    border: 1px solid #E6E6E6;
    border-radius: 15px;
    color: #4A4A4A;
    font-size: 11px;
    text-decoration: none;
    padding: 5px 17px;
    margin-right: 10px;
    display: inline-block;
  }
}
